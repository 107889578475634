export default {
    computed: {
        sidebarTemplate() {
            switch (this.asideType) {
            case 'mini':
                return () => import(`../components/Sidebar/MiniAside/index.vue`)
                break;
            case 'big':
                return () => import(`../components/Sidebar/BigAside/index.vue`)
                break;
            case 'dynamic':
                return () => import(`../components/Sidebar/ВynamicAside/index.vue`)
                break;
            default:
                return () => import(`../components/Sidebar/MiniAside/index.vue`)
            }
        },
        asideWidth() {
            if(this.config?.aside_setting?.width) {
                return this.config.aside_setting.width
            } else
                return 300
        }
    }
}