<template>
    <a-dropdown :trigger="['click']">
        <a-button 
            type="ui"
            ghost 
            flaticon 
            shape="circle"
            icon="fi-rr-plus" />
        <a-menu slot="overlay">
            <a-menu-item key="0" @click="addTask()">
                Добавить задачу
            </a-menu-item>
            <a-menu-item key="1" @click="$store.commit('meeting/SET_EDIT_DRAWER', { show: true, model: 'main' })">
                Добавить конференцию
            </a-menu-item>
            <a-menu-item key="2" @click="addEvent()">
                Добавить событие
            </a-menu-item>
            <a-menu-item key="3" @click="addOrganization()">
                Добавить организацию
            </a-menu-item>
        </a-menu>
    </a-dropdown>
</template>

<script>
import eventBus from '@/utils/eventBus.js'
export default {
    methods: {
        addTask() {
            this.$store.dispatch('task/sidebarOpen', {
                task_type: 'task'
            })
        },
        addEvent() {
            eventBus.$emit('open_event_form', null, null, null, null, 'default')
        },
        addOrganization() {
            eventBus.$emit('create_organization', { organization_type: 'organization' })
        }
    }
}
</script>